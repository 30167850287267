header {
  @extend %absolute;
  @extend %flex-ac-jc;
  width: 100%;

  background: var(--color--primary_light, #FBF9F4);
  z-index: 7;

  &.header--fixed {
    @extend %fixed;
    top: 0;
    left: 0;
  }

  @include breakpoint-min($desktop) { font-size: 76px; }
}

.header__container {
  @extend %relative;
  @extend %flex-ac-jc;
  gap: 14px;
  padding-top: 16px;

  border-bottom: 1px solid transparent;

  @include breakpoint-max($desktop - 1) { padding-bottom: 16px; }
  @include breakpoint-min($desktop) { padding-top: 12px; }
}
.header__container::before {
  content: '';
  @extend %absolute;
  left: var(--container-padding);
  bottom: -1px;

  display: block;
  width: calc(100% - var(--container-padding)*2);
  height: 1px;

  background-color: var(--color--brand_light, #CBD6C5);
}

.header .nav__link {
  @include breakpoint-min($desktop) { font-size: 16px; }
}