.certificates__title {
  // margin-bottom: 56px;
  margin-bottom: 48px;

  color: var(--color--primary_dark, #292E27);
  @extend %text-ac;

  // @include breakpoint-min($tablet) { margin-bottom: 64px;}
  // @include breakpoint-min($desktop) { margin-bottom: 80px;}
  @include breakpoint-min($desktop) { margin-bottom: 56px;}
  @include breakpoint-min($desktopL) { margin-bottom: 80px;}
}
.certificates__list {
  display: flex;
}
.certificate__link {
  @extend %flex-jc;
  // width: 300px;
  height: 236px;
  // width: auto;

  border: 1px solid var(--color--brand_light, #CBD6C5);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: var(--transition);

  @include breakpoint-min($mobile) { height: 296px; }
  @include breakpoint-min($tablet) { height: 236px; }
  @include breakpoint-min($desktop) { height: 240px; }
  @include breakpoint-min($desktopL) { height: 284px; }
  // @include breakpoint-min($mobile) { width: 420px; height: 296px; }
  // @include breakpoint-min($tablet) { width: 336px; height: 236px; }
  // @include breakpoint-min($desktop) { width: 344px; height: 240px; }
  // @include breakpoint-min($desktopL) { width: 408px; height: 284px; }
}
.certificate__link:focus { outline: none;}
.certificate__img {
  object-fit: cover;
  object-position: top;
  // aspect-ratio: 4 / 3;
  width: 100%;
  height: auto;

  transition: var(--transition);
  
  .test & {
    max-height: 300px;
    width: auto;
  }
}
.certificate__link:hover .certificate__img, .certificate__link:focus .certificate__img  { scale: 1.05;}
.certificates-swiper-pagination {
  @extend %flex-ac-jc;
  margin-top: 20px;
  margin-bottom: 10px;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;

  border-radius: 50;
  background-color: var(--color--brand_light, #CBD6C5);
  opacity: 1;
  transition: 200ms linear;
}
.swiper-pagination-bullet-active,
.swiper-pagination-bullet:hover, 
.swiper-pagination-bullet:focus { background-color: var(--color--brand, #7F8F77); outline: none;}
.swiper-pagination-bullet:active {  background-color: var( --color--brand_dark, #41493D); }

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
}

.swiper-button-prev,
.swiper-button-next {
  @extend %flex-ac-jc;
  width: 48px;
  height: 48px;
  padding: 8px;

  @include breakpoint-max($desktop - 1) { display: none;}
}
.swiper-button-prev { left: 0; };
.swiper-button-next { right: 0; };
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 0;
}
// .swiper-button-prev,
// .swiper-button-next {
//   @extend %flex-ac-jc;
//   width: 48px;
//   height: 48px;
//   padding: 8px;

//   background-color: transparent;
  
//   & svg { fill: var(--color--brand_dark, #41493D); }

//   &:hover { & svg {  fill: var(--color--brand, #7F8F77);}}
//   &:focus { outline: 4px solid var(--color--brand, #7F8F77); border-radius: 30px; }
//   &:focus:not(:focus-visible) { 
//     outline: none; 
//     & svg { 
//       fill: var(--color--brand_dark, #41493D); 
//     }
//   }
//   &:active { & svg {  fill: var(--color--primary_dark, #292E27);}}

//   @include breakpoint-max($desktop - 1) { display: none;}
// }
// .swiper-button-prev { left: 0; };
// .swiper-button-next { right: 0; };
// .swiper-button-prev::after,
// .swiper-button-next::after {
//   font-size: 0;
// }

// ===== simplelightbox style =====
.sl-overlay {
  @extend %fixed-tlrb;
  background: rgba(41, 46, 39, 0.98);
  opacity: 1 !important;
  z-index: 8;
}
.sl-wrapper {
  @extend %fixed;
  top: 0;
  left: 0;
  z-index: 9;

  @extend %flex-ac-jc;
  width: 100%;
  height: 100%;
  padding: 16px;

  touch-action: none;
}
.sl-image {
  @extend %fixed;
  z-index: 3;
  touch-action: none;

  & img {
    margin: 0 auto;
    width: 100%;
    max-height: calc(100vh - 32px) !important;
    object-fit: contain;
  }

  @include breakpoint-max($tablet - 1) { max-height: calc(90vh - 32px) !important; }
}
.sl-close {
  @extend %absolute;
  z-index: 4;
  top: 16px;
  right: 16px;

  width: 48px;
  height: 48px;
  background-color: var(--color--brand_light, #CBD6C5);
  // background-image: url('/static/images/icons/icon-btn-cross.svg');
  -webkit-mask-image: url('/static/images/icons/icon-btn-cross.svg');
  mask-image: url('/static/images/icons/icon-btn-cross.svg');
  font-size: 0;

  &:hover {background-color: var(--color--brand, #7F8F77);}
  &:focus { outline: 4px solid var(--color--brand, #7F8F77); border-radius: 30px; }
  &:focus:not(:focus-visible) { outline: none; background-color: var(--color--brand_dark, #41493D);}
  &:active { background-color: var(--color--primary_dark, #292E27); }
}
.sl-navigation {
  @extend %absolute;
  @extend %top-left-transform-50;
  z-index: 6;
  
  display: flex !important;
  @extend %flex-ac-jsb;
  width: calc(100vw - 32px);
  height: 0px;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: var(--color--brand_dark, #41493D);
}
.sl-prev, 
.sl-next {
  background-color: var(--color--brand_light, #CBD6C5);
  z-index: 6;
}
.swiper-button-prev,
.swiper-button-next,
.sl-prev, 
.sl-next {
  width: 48px;
  height: 48px;
  
  font-size: 0;
  &:hover { background-color: var(--color--brand, #7F8F77);}
  &:focus { outline: 4px solid var(--color--brand, #7F8F77); border-radius: 30px; }
  &:focus:not(:focus-visible) { outline: none; background-color: var(--color--brand_dark, #41493D);}
  &:active { background-color: var(--color--primary_dark, #292E27); }
}

.swiper-button-prev,
.sl-prev {
  -webkit-mask-image: url('/static/images/icons/icon-btn-prev.svg');
  mask-image: url('/static/images/icons/icon-btn-prev.svg');
}
.swiper-button-next,
.sl-next {
  -webkit-mask-image: url('/static/images/icons/icon-btn-next.svg');
  mask-image: url('/static/images/icons/icon-btn-next.svg');
}

.sl-counter {
  display: none !important;
}