.problems__container {
  @extend %flex-ac-dc;
  // gap: 44px;
}

.problems__title {
  // margin-bottom: 56px;
  margin-bottom: 48px;

  @extend %text-ac;
  color: var(--color--brand_dark, #41493D);

  // @include breakpoint-min($tablet) { margin-bottom: 64px;}
  // @include breakpoint-min($desktop) { margin-bottom: 80px;}
  @include breakpoint-min($desktop) { margin-bottom: 56px;}
  @include breakpoint-min($desktopL) { margin-bottom: 80px;}
}

.problems__box {
  @extend %flex-ac-dc;
  gap: 16px;
}
.problems__box--2 {
  gap: 24px;
  margin-top: 40px;

  @include breakpoint-min($desktop) {
    gap: 28px;
    margin-top: 56px;
  }
}

.problems__text {
  @extend %text-ac;
  color: var(--color--brand_dark);
}

// .problems__bubble-list {
//   @extend %relative;
//   @extend %flex-ac-jc-ww;
//   gap: 16px;

//   @include breakpoint-min($tablet) { gap: 32px; }
//   @include breakpoint-min($desktop) { align-items: flex-start; column-gap: 26px; row-gap: 4px}
// }
// .problems__bubble-item {
//   @extend %flex-0-0;
//   @extend %flex-ac-jc;
//   @extend %text-ac;

//   font: var(--font--body-r);
//   color: var(--color--primary_dark);

//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }
// .problems__bubble-item:nth-of-type(1),
// .problems__bubble-item:nth-of-type(5) {
//   flex-basis: 248px;
//   width: 248px;
//   height: 178px;
//   padding: 16px;
//   margin: 0 calc((100% - 248px) / 2);

//   background-image: url('/static/images/icons/ellipse-m.svg');
// }
// .problems__bubble-item:nth-of-type(2),
// .problems__bubble-item:nth-of-type(3),
// .problems__bubble-item:nth-of-type(4),
// .problems__bubble-item:nth-of-type(6),
// .problems__bubble-item:nth-of-type(7) {
//   flex-basis: 156px;
//   width: 156px;
//   height: 124px;
//   padding: 16px;

//   background-image: url('/static/images/icons/ellipse-s.svg');
// }
// .problems__bubble-item:nth-of-type(8) {
//   flex-basis: 328px;
//   width: 328px;
//   height: 174px;
//   padding: 16px;
//   margin: 0 calc((100% - 328px) / 2);

//   background-image: url('/static/images/icons/ellipse-l.svg');
// }
// .problems__bubble-item:nth-of-type(2),
// .problems__bubble-item:nth-of-type(6) { margin-right: auto; }
// .problems__bubble-item:nth-of-type(4) { margin: 0 calc((100% - 156px) / 2) 20px; }

// @include breakpoint-max(359px) {
//   .problems__bubble-item:nth-of-type(n) { margin: 0}
//   .problems__bubble-item:nth-of-type(n) { margin: 0 calc((100% - 156px) / 2)}
// }
// @include breakpoint-min($tablet) {
//   .problems__bubble-item:nth-of-type(2),
//   .problems__bubble-item:nth-of-type(4) { margin: 0; }
//   .problems__bubble-item:nth-of-type(3) { margin: 0 auto; }
//   .problems__bubble-item:nth-of-type(6),
//   .problems__bubble-item:nth-of-type(7) { position: absolute; bottom: 138px; }
//   .problems__bubble-item:nth-of-type(6) { left: 0; }
//   .problems__bubble-item:nth-of-type(7) { right: 0; }
//   .problems__bubble-item:nth-of-type(8) { margin-top: 16px; }
// }
// @include breakpoint-min($desktop) {
//   .problems__bubble-item:nth-of-type(n) { margin: 0; position: static; }
//   .problems__bubble-item:nth-of-type(1),
//   .problems__bubble-item:nth-of-type(5) { margin-top: 46px; }  
//   .problems__bubble-item:nth-of-type(3) { margin-top: 58px; }
//   .problems__bubble-item:nth-of-type(6),
//   .problems__bubble-item:nth-of-type(7) { margin-top: 8px; }
//   .problems__bubble-item:nth-of-type(7) { order: 8; }
// }

.problems-cards__list {
  @extend %flex-ac-jc-ww;
  --gap: 16px;
  gap: var(--gap);

  @include breakpoint-min($desktop) {  --gap: 24px; }
}
.problems-cards__item {
  @extend %cardSet__item;
  @extend %flex-dc;
  align-self: stretch;
  gap: 14px;
  padding: 20px;

  color: var(--color--primary_dark, #292E27);

  background-color: var(--color--brand_light, #CBD6C5);
  @extend %border-radius;

  @include breakpoint-min($mobile) { --items: 2; padding: 20px 8px 16px;}
  @include breakpoint-min($tablet) { padding: 32px;}
  @include breakpoint-min($desktop) { --items: 4; padding: 32px 16px;}
}

.problems-card__icon {
  margin: 0 auto;
}
.problems-card__item {
  @extend %relative;
  display: flex;
  align-items: center;
  padding-left: 24px;

  // font: var(--font--body-r);

  &::before {
    content: '';
    @extend %absolute;
    top: 10px;
    left: 10px;

    width: 4px;
    height: 4px;

    border-radius: 50%;
    background-color: var(--color--primary_dark, #292E27);
  }
}


.problems__list {
  @extend %flex-ac-jc-ww;
  --c-gap: 18px;
  column-gap: var(--c-gap);
  row-gap: 16px;
  width: 246px;

  @include breakpoint-min($tablet) { row-gap: 20px; width: 512px; }
  @include breakpoint-min($desktop) { --c-gap: 30px; width: 528px; }
}
.problems__item {
  @extend %flex-ac;
  gap: 8px;
  width: 100%;

  @include breakpoint-min($tablet) { width: calc((100% - var(--c-gap)) / 2);}
}
.problems__item::before {
  flex: 0 0 24px;
  content: '';

  display: block;
  width: 24px;
  height: 24px;

  background-image: url('/static/images/icons/icon-star.svg');
}
@include breakpoint-max($tablet - 1) {
  .problems__item:nth-of-type(2),
  .problems__item:nth-of-type(4),
  .problems__item:nth-of-type(6) {
    order: 5;
  }
}


// for animate
// .problems-card {--animate-delay: 350ms; }
.problems-card:nth-of-type(2) { --animate-delay: calc(350ms * 2)}
.problems-card:nth-of-type(3) { --animate-delay: calc(350ms * 3)}
.problems-card:nth-of-type(4) { --animate-delay: calc(350ms * 4)}
.problems__box--2 {--animate-delay: calc(350ms * 5)}