.lang {
  @extend %relative;
  width: 50px;
  // height: 56px;
  height: 54px;
  z-index: 10;
}
.lang__list {
  @extend %absolute;
  top: 0;
  left: 0;
  // z-index: 10;
}

.lang__item {
  background-color: var(--color--primary_light, #fbf9f4);
  border-bottom: 1px solid var(--color--brand_light, #cbd6c5);

  // &.isHidden { display: none; }
  transition: all 200ms linear;
  opacity: 1;

  &.isHidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
// .lang__item:nth-of-type(1) {transition-delay: 50ms}
// .lang__item:nth-of-type(2) {transition-delay: 100ms}

.lang__link {
  min-width: 50px;
  // z-index: 10;
}
button.lang__link {
  background-color: transparent;
  // z-index: 10;
}

.mobile-menu .lang {
  width: 100%;
}
.mobile-menu .lang__list {
  position: static;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.mobile-menu .lang__item {
  border-bottom-color: transparent;
}
