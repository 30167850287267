.hero {
  padding-top: 80px;
  @include breakpoint-min($tablet) {
    padding-top: 48px;
  }
  @include breakpoint-min-max($desktop, $desktopL - 1) {
    padding: 60px 0 100px;
  }
  @include breakpoint-min($desktopL) {
    padding-top: 80px;
  }
}
.hero__container {
  @extend %relative;
  @extend %flex-dc;
  gap: 32px;

  @include breakpoint-min($tablet) {
    gap: 40px;
  }
  @include breakpoint-min($desktop) {
    flex-direction: row;
    gap: 24px;
    max-width: 896px;
  }
  @include breakpoint-min($desktopL) {
    max-width: 1056px;
  }
}
.hero .container:last-of-type {
  @extend %relative;

  &::before {
    content: '';
    @extend %absolute;
    left: var(--container-padding);
    bottom: calc(var(--section-padding) * (-1));

    display: block;
    width: calc(100% - var(--container-padding) * 2);
    height: 1px;

    background-color: var(--color--brand_light, #cbd6c5);
  }

  @include breakpoint-min-max($desktop, $desktopL - 1) {
    --section-padding: 100px;
  }
}

.hero__box {
  @extend %flex-ac-dc;

  @include breakpoint-min($desktop) {
    padding-top: 30px;
  }
  @include breakpoint-min($desktopL) {
    padding-top: 75px;
  }
}

.hero__title {
  margin-bottom: 36px;

  @extend %text-ac;
  color: var(--color--brand_dark, #41493d);

  @include breakpoint-min($mobile) {
    margin-bottom: 44px;
  }
}
.hero__subtitle {
  @extend %text-ac;
  margin-bottom: 20px;
}
.hero__img {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  margin: 0 auto;

  object-fit: cover;
  border-radius: var(--border-radius);

  @include breakpoint-min($mobile) {
    width: 448px;
    height: 578px;
  }
  @include breakpoint-min($tablet) {
    width: 336px;
    height: 476px;
  }
  @include breakpoint-min($desktop) {
    width: 344px;
    height: 488px;
  }
  @include breakpoint-min($desktopL) {
    width: 408px;
    height: 578px;
  }
}

.hero .messengers {
  position: relative;
  display: grid;
  grid-template-rows: 1fr;

  margin-top: 12px;
  transition: 150ms linear;

  // &.isHidden {
  //   grid-template-rows: 0fr;
  // }

  @include breakpoint-min-max($tablet, $desktop - 1) {
    &.isHidden {
      grid-template-rows: 0fr;
    }
  }

  .messengers__inner {
    overflow: hidden;
  }

  .messengers__list {
    gap: 12px;
  }
  .messenger__link {
    width: 56px;
    height: 56px;
    padding: 8px;

    border-radius: 30px;
    background: var(--color--brand_light, #cbd6c5);
    // transition: var(--transition);
  }

  &.isHidden {
    scale: (0.5);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

// for animate
.hero__box.animate__animated {
  --animate-delay: 100ms;
}
.hero__img.animate__animated {
  --animate-delay: 600ms;
}
