:root {
  --font-family--title: "Cormorant", serif;
  --font-family--body: "Raleway", sans-serif;
  --font--body-small-l: 300 14px / 100% var(--font-family--body);
  --font--body-l: 300 16px / 160% var(--font-family--body);
  --font--body-r: 400 16px / 160% var(--font-family--body);
  --font--label-r: 400 18px / 110% var(--font-family--body);
  --font--link-r: 400 16px / 160% var(--font-family--body);
  --color--primary_light: #fbf9f4;
  --color--primary_dark: #292e27;
  --color--brand_light: #cbd6c5;
  --color--brand_dark: #41493d;
  --color--brand: #7f8f77;
  --color--gray: #d8dbd7;
  --items: 1;
  --gap: 12px;
  --border-radius: 14px;
  --transition: .3s linear;
}

.condition__title, .problems__item, .contact__link, .conditions__container, .problems__box, .problems__container, .about__container, .hero__box, .messenger__link, .footer__container, footer, .sl-navigation, .sl-wrapper, .swiper-button-prev, .swiper-button-next, .certificates-swiper-pagination, .condition__btn, .mobile-menu__btn, .messengers__list, .header__container, header, .btn-cta, .problems__list, .problems-cards__list {
  align-items: center;
  display: flex;
}

.sl-wrapper, .swiper-button-prev, .swiper-button-next, .certificates-swiper-pagination, .condition__btn, .mobile-menu__btn, .messengers__list, .header__container, header, .btn-cta, .problems__list, .problems-cards__list {
  justify-content: center;
}

.sl-navigation {
  justify-content: space-between;
}

.condition__desc, .conditions__list, .problems-cards__item, .about__box:nth-of-type(2), .hero__container, .mobile-menu, .contacts__list {
  flex-direction: column;
  display: flex;
}

.conditions__container, .problems__box, .problems__container, .about__container, .hero__box, .messenger__link, .footer__container, footer {
  flex-direction: column;
}

.certificate__link {
  justify-content: center;
  display: flex;
}

.problems__list, .problems-cards__list {
  flex-wrap: wrap;
}

.condition__btn, .condition__title, .problems-card__item, .about__item, .about__container, .hero .container:last-of-type, .hero__container, .mobile-menu__btn, .lang, .header__container {
  position: relative;
}

.sl-navigation, .sl-close, .condition__btn:before, .problems-card__item:before, .about__item:before, .about__container:before, .hero .container:last-of-type:before, .mobile-menu__btn .burger-line, .lang__list, .header__container:before, header, .visually-hidden {
  position: absolute;
}

.sl-image, .sl-wrapper, .mobile-menu, header.header--fixed, .sl-overlay {
  position: fixed;
}

.sl-overlay {
  inset: 0;
}

.sl-navigation {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.certificates__title, .conditions__title, .problems__text, .problems__title, .hero__subtitle, .hero__title, .copyright, .footer__subtitle, .footer__title {
  text-align: center;
}

.condition__title {
  cursor: pointer;
}

.problems-cards__item {
  border-radius: var(--border-radius);
  flex-basis: calc((100% - var(--gap) * (var(--items)  - 1)) / var(--items));
}

@media (min-width: 768px) {
  .problems-cards__item {
    --items: 2;
  }
}

@media (min-width: 1200px) {
  .problems-cards__item {
    --items: 4;
  }
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

.btn-cta {
  min-width: 179px;
  height: 60px;
  color: var(--color--brand_dark, #41493d);
  font: var(--font--label-r);
  font-variant-numeric: lining-nums proportional-nums;
  border: 1px solid var(--color--brand_dark, #41493d);
  border-radius: 32px;
  outline: none;
  padding: 20px 40px;
}

.btn-cta:hover {
  background: var(--color--brand_light, #cbd6c5);
}

.btn-cta:focus {
  border: 6px solid var(--color--brand, #7f8f77);
}

.btn-cta:focus:not(:focus-visible) {
  border: 1px solid var(--color--brand_dark, #41493d);
}

.btn-cta:active {
  color: var(--color--primary_dark, #292e27);
  border: 1px solid var(--color--primary_dark, #292e27);
  background: var(--color--brand, #7f8f77);
}

header {
  width: 100%;
  background: var(--color--primary_light, #fbf9f4);
  z-index: 7;
}

header.header--fixed {
  top: 0;
  left: 0;
}

@media (min-width: 1200px) {
  header {
    font-size: 76px;
  }
}

.header__container {
  border-bottom: 1px solid #0000;
  gap: 14px;
  padding-top: 16px;
}

@media (max-width: 1199px) {
  .header__container {
    padding-bottom: 16px;
  }
}

@media (min-width: 1200px) {
  .header__container {
    padding-top: 12px;
  }
}

.header__container:before {
  content: "";
  left: var(--container-padding);
  width: calc(100% - var(--container-padding) * 2);
  height: 1px;
  background-color: var(--color--brand_light, #cbd6c5);
  display: block;
  bottom: -1px;
}

@media (min-width: 1200px) {
  .header .nav__link {
    font-size: 16px;
  }
}

footer {
  width: 100%;
  color: var(--color--primary_dark, #292e27);
  background-color: var(--color--brand_light, #cbd6c5);
  gap: 40px;
  padding: 44px 0 24px;
}

@media (min-width: 1200px) {
  footer {
    padding: 80px 0 40px;
  }
}

@media (min-width: 1440px) {
  footer {
    gap: 56px;
  }
}

.footer__top, .footer__bottom {
  width: 100%;
}

.footer__container {
  gap: 16px;
}

@media (min-width: 1200px) {
  .footer__container {
    flex-direction: row;
    justify-content: center;
    gap: 124px;
  }
}

.footer__box:first-of-type {
  max-width: 300px;
}

.footer__title {
  color: var(--color--brand_dark, #41493d);
}

@media (min-width: 1200px) {
  .footer__title {
    text-align: start;
  }
}

.footer__subtitle {
  font: var(--font--body-l);
  color: var(--color--primary_dark, #292e27);
}

@media (min-width: 1200px) {
  .footer__subtitle {
    text-align: start;
  }
}

.contacts__list {
  gap: 4px;
}

.contact__link {
  font: var(--font--body-r);
  font-variant-numeric: lining-nums proportional-nums;
  color: var(--color--primary_dark, #292e27);
  border-radius: 26px;
  gap: 6px;
  padding: 7px 8px;
  text-decoration: none;
}

.contact__link:hover {
  color: var(--color--brand, #7f8f77);
}

.contact__icon {
  flex: 0 0 24px;
}

.messengers__list {
  gap: 14px;
}

.messenger {
  width: 64px;
}

.messenger__link {
  color: var(--color--primary_dark, #292e27);
  border-radius: 10px;
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
}

.messenger__link:hover {
  color: var(--color--brand_dark, #41493d);
}

.messenger__link:hover svg {
  fill: var(--color--brand, #7f8f77);
}

.messenger__link:focus {
  outline: 5px solid var(--color--brand, #7f8f77);
}

.messenger__link:focus svg {
  fill: var(--color--brand_dark, #41493d);
}

.messenger__link:focus:not(:focus-visible) {
  outline: none;
}

.messenger__link:active svg {
  fill: var(--color--brand_dark, #41493d);
}

.messenger__icon {
  flex: 0 0 40px;
}

.copyright {
  font: var(--font--body-small-l);
  font-variant-numeric: lining-nums proportional-nums;
  color: var(--color--primary_dark, #292e27);
}

.lang {
  width: 50px;
  height: 54px;
  z-index: 10;
}

.lang__list {
  top: 0;
  left: 0;
}

.lang__item {
  background-color: var(--color--primary_light, #fbf9f4);
  border-bottom: 1px solid var(--color--brand_light, #cbd6c5);
  opacity: 1;
  transition: all .2s linear;
}

.lang__item.isHidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.lang__link {
  min-width: 50px;
}

button.lang__link {
  background-color: #0000;
}

.mobile-menu .lang {
  width: 100%;
}

.mobile-menu .lang__list {
  flex-direction: row;
  gap: 24px;
  display: flex;
  position: static;
}

.mobile-menu .lang__item {
  border-bottom-color: #0000;
}

.mobile-menu__btn {
  width: 48px;
  height: 48px;
  color: var(--color--brand_dark);
  background-color: #0000;
  margin-left: auto;
  padding: 8px;
}

body.mobileMenuIsOpen .mobile-menu__btn {
  z-index: 20;
}

@media (min-width: 1200px) {
  .mobile-menu__btn {
    display: none;
  }
}

.mobile-menu__btn .burger-line {
  width: 32px;
  height: 2px;
  background-color: currentColor;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
}

.burger-line:first-child {
  transform: translateY(8px);
}

.burger-line:last-child {
  transform: translateY(-8px);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:first-child {
  transform: rotate(-45deg);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:nth-child(2n) {
  opacity: 0;
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.mobile-menu {
  width: 100%;
  height: 100%;
  background-color: var(--color--primary_light, #fbf9f4);
  transition: all var(--transition);
  z-index: 6;
  padding-top: 81px;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.mobile-menu.isHidden {
  top: -101%;
}

.mobile-menu.isHidden button, .mobile-menu.isHidden a {
  pointer-events: none;
  visibility: hidden;
}

.mobile-menu__container {
  padding-top: 32px;
  padding-bottom: 32px;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 0s;
  --animate-repeat: 1;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__animated {
  opacity: 0;
}

.init-animation .animate__animated, .init-animation.animate__animated {
  opacity: 1;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
  animation-name: fadeInUp;
}

.hero {
  padding-top: 80px;
}

@media (min-width: 768px) {
  .hero {
    padding-top: 48px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .hero {
    padding: 60px 0 100px;
  }
}

@media (min-width: 1440px) {
  .hero {
    padding-top: 80px;
  }
}

.hero__container {
  gap: 32px;
}

@media (min-width: 768px) {
  .hero__container {
    gap: 40px;
  }
}

@media (min-width: 1200px) {
  .hero__container {
    max-width: 896px;
    flex-direction: row;
    gap: 24px;
  }
}

@media (min-width: 1440px) {
  .hero__container {
    max-width: 1056px;
  }
}

.hero .container:last-of-type:before {
  content: "";
  left: var(--container-padding);
  bottom: calc(var(--section-padding) * -1);
  width: calc(100% - var(--container-padding) * 2);
  height: 1px;
  background-color: var(--color--brand_light, #cbd6c5);
  display: block;
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .hero .container:last-of-type {
    --section-padding: 100px;
  }
}

@media (min-width: 1200px) {
  .hero__box {
    padding-top: 30px;
  }
}

@media (min-width: 1440px) {
  .hero__box {
    padding-top: 75px;
  }
}

.hero__title {
  color: var(--color--brand_dark, #41493d);
  margin-bottom: 36px;
}

@media (min-width: 480px) {
  .hero__title {
    margin-bottom: 44px;
  }
}

.hero__subtitle {
  margin-bottom: 20px;
}

.hero__img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: var(--border-radius);
  flex-shrink: 0;
  margin: 0 auto;
}

@media (min-width: 480px) {
  .hero__img {
    width: 448px;
    height: 578px;
  }
}

@media (min-width: 768px) {
  .hero__img {
    width: 336px;
    height: 476px;
  }
}

@media (min-width: 1200px) {
  .hero__img {
    width: 344px;
    height: 488px;
  }
}

@media (min-width: 1440px) {
  .hero__img {
    width: 408px;
    height: 578px;
  }
}

.hero .messengers {
  grid-template-rows: 1fr;
  margin-top: 12px;
  transition: all .15s linear;
  display: grid;
  position: relative;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .hero .messengers.isHidden {
    grid-template-rows: 0fr;
  }
}

.hero .messengers .messengers__inner {
  overflow: hidden;
}

.hero .messengers .messengers__list {
  gap: 12px;
}

.hero .messengers .messenger__link {
  width: 56px;
  height: 56px;
  background: var(--color--brand_light, #cbd6c5);
  border-radius: 30px;
  padding: 8px;
}

.hero .messengers.isHidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  scale: .5;
}

.hero__box.animate__animated {
  --animate-delay: .1s;
}

.hero__img.animate__animated {
  --animate-delay: .6s;
}

.about__container:before {
  content: "";
  left: var(--container-padding);
  bottom: calc(var(--section-padding) * -1);
  width: calc(100% - var(--container-padding) * 2);
  height: 1px;
  background-color: #cbd6c5;
  display: block;
}

.about__title {
  color: var(--color--brand_dark, #41493d);
  margin-bottom: 48px;
}

@media (min-width: 1200px) {
  .about__title {
    margin-bottom: 56px;
  }
}

@media (min-width: 1440px) {
  .about__title {
    margin-bottom: 80px;
  }
}

.about__box-wrap {
  grid-template-columns: 1fr;
  row-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .about__box-wrap {
    grid-template-rows: auto 1fr;
    grid-template-columns: 336px auto;
    gap: 16px;
  }
}

@media (min-width: 1200px) {
  .about__box-wrap {
    max-width: 896px;
    grid-template-columns: 344px auto;
    gap: 24px;
  }
}

@media (min-width: 1440px) {
  .about__box-wrap {
    max-width: 1056px;
    grid-template-columns: 408px auto;
  }
}

.about__box:nth-of-type(2) {
  gap: 8px;
}

@media (max-width: 767px) {
  .about__box {
    grid-column: 1;
  }
}

@media (min-width: 768px) {
  .about__box:nth-of-type(3) {
    grid-column: 1 / 3;
  }
}

@media (min-width: 1200px) {
  .about__box:nth-of-type(1) {
    grid-row: 1 / 3;
  }

  .about__box:nth-of-type(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.about__img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: var(--border-radius);
  flex-shrink: 0;
}

@media (min-width: 480px) {
  .about__img {
    width: 448px;
    height: 672px;
  }
}

@media (min-width: 768px) {
  .about__img {
    width: 336px;
    height: 504px;
  }
}

@media (min-width: 1200px) {
  .about__img {
    width: 344px;
    height: 515px;
  }
}

@media (min-width: 1440px) {
  .about__img {
    width: 408px;
    height: 612px;
  }
}

.about__text {
  font: var(--font--body-l);
  font-variant-numeric: lining-nums proportional-nums;
}

.about__text:not(:last-of-type) {
  margin-bottom: 12px;
}

.about__link {
  font: var(--font--link-r);
  color: var(--color--primary_dark, #292e27);
  border-radius: 26px;
}

.about__link:hover {
  color: var(--color--brand, #7f8f77);
}

.about__link:focus {
  outline: 6px solid var(--color--brand_light, #cbd6c5);
}

.about__link:focus:not(:focus-visible) {
  color: var(--color--brand_dark, #41493d);
  outline: none;
}

.about__link:hover {
  color: var(--color--brand_dark, #41493d);
}

.about__link--education {
  height: 50px;
  border-radius: 26px;
  padding: 12px 8px;
  display: inline-block;
}

.about__item {
  font: var(--font--body-l);
  align-items: center;
  padding-left: 24px;
  display: flex;
}

.about__item:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--color--primary_dark, #292e27);
  border-radius: 50%;
  top: 10px;
  left: 10px;
}

.animate__animated.about__box:nth-of-type(2) {
  --animate-delay: .5s;
}

.animate__animated.about__box:nth-of-type(3) {
  --animate-delay: 1s;
}

.problems__title {
  color: var(--color--brand_dark, #41493d);
  margin-bottom: 48px;
}

@media (min-width: 1200px) {
  .problems__title {
    margin-bottom: 56px;
  }
}

@media (min-width: 1440px) {
  .problems__title {
    margin-bottom: 80px;
  }
}

.problems__box {
  gap: 16px;
}

.problems__box--2 {
  gap: 24px;
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .problems__box--2 {
    gap: 28px;
    margin-top: 56px;
  }
}

.problems__text {
  color: var(--color--brand_dark);
}

.problems-cards__list {
  --gap: 16px;
  gap: var(--gap);
}

@media (min-width: 1200px) {
  .problems-cards__list {
    --gap: 24px;
  }
}

.problems-cards__item {
  color: var(--color--primary_dark, #292e27);
  background-color: var(--color--brand_light, #cbd6c5);
  align-self: stretch;
  gap: 14px;
  padding: 20px;
}

@media (min-width: 480px) {
  .problems-cards__item {
    --items: 2;
    padding: 20px 8px 16px;
  }
}

@media (min-width: 768px) {
  .problems-cards__item {
    padding: 32px;
  }
}

@media (min-width: 1200px) {
  .problems-cards__item {
    --items: 4;
    padding: 32px 16px;
  }
}

.problems-card__icon {
  margin: 0 auto;
}

.problems-card__item {
  align-items: center;
  padding-left: 24px;
  display: flex;
}

.problems-card__item:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--color--primary_dark, #292e27);
  border-radius: 50%;
  top: 10px;
  left: 10px;
}

.problems__list {
  --c-gap: 18px;
  column-gap: var(--c-gap);
  width: 246px;
  row-gap: 16px;
}

@media (min-width: 768px) {
  .problems__list {
    width: 512px;
    row-gap: 20px;
  }
}

@media (min-width: 1200px) {
  .problems__list {
    --c-gap: 30px;
    width: 528px;
  }
}

.problems__item {
  width: 100%;
  gap: 8px;
}

@media (min-width: 768px) {
  .problems__item {
    width: calc((100% - var(--c-gap)) / 2);
  }
}

.problems__item:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("icon-star.5be79446.svg");
  flex: 0 0 24px;
  display: block;
}

@media (max-width: 767px) {
  .problems__item:nth-of-type(2), .problems__item:nth-of-type(4), .problems__item:nth-of-type(6) {
    order: 5;
  }
}

.problems-card:nth-of-type(2) {
  --animate-delay: calc(.35s * 2);
}

.problems-card:nth-of-type(3) {
  --animate-delay: calc(.35s * 3);
}

.problems-card:nth-of-type(4) {
  --animate-delay: calc(.35s * 4);
}

.problems__box--2 {
  --animate-delay: calc(.35s * 5);
}

.conditions {
  background-color: var(--color--brand_light, #cbd6c5);
}

.conditions__title {
  color: var(--color--primary_dark, #292e27);
  margin-bottom: 48px;
}

@media (min-width: 1200px) {
  .conditions__title {
    margin-bottom: 56px;
  }
}

@media (min-width: 1440px) {
  .conditions__title {
    margin-bottom: 80px;
  }
}

.conditions__list {
  gap: 4px;
}

@media (min-width: 768px) {
  .conditions__list {
    max-width: 512px;
  }
}

@media (min-width: 1200px) {
  .conditions__list {
    max-width: 528px;
  }
}

@media (min-width: 1440px) {
  .conditions__list {
    max-width: 624px;
  }
}

.condition {
  border-bottom: 1px solid var(--color--brand, #7f8f77);
}

.condition:first-of-type {
  border-top: 1px solid var(--color--brand, #7f8f77);
}

.condition__title {
  width: 100%;
  height: 72px;
  color: var(--color--primary_dark, #292e27);
  text-align: left;
  background-color: var(--color--brand_light, #cbd6c5);
  justify-content: space-between;
}

.condition__btn {
  width: 48px;
  height: 48px;
  --background-color: var(--color--brand_dark, #41493d);
  background-color: #0000;
  padding: 8px;
}

.condition__btn.isActive {
  --background-color: var(--color--primary_dark, #292e27);
}

.condition__btn:hover {
  --background-color: var(--color--brand, #7f8f77);
}

.condition__btn:focus {
  outline: 4px solid var(--color--brand, #7f8f77);
  border-radius: 30px;
}

.condition__btn:focus:not(:focus-visible) {
  outline: none;
}

.condition__btn:active {
  --background-color: var(--color--primary_dark, #292e27);
}

.condition__btn:before, .condition__btn:after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: var(--background-color);
  border-radius: 12px;
  transition: all .2s linear;
  display: block;
}

.condition__btn:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(90deg);
}

.condition__btn.isActive:before {
  width: 0;
}

.condition__desc-box {
  grid-template-rows: 1fr;
  transition: all .2s linear;
  display: grid;
}

.condition__desc-box.isHidden {
  grid-template-rows: 0fr;
}

.condition__desc-inner {
  overflow: hidden;
}

.condition__desc {
  border-top: 1px solid var(--color--brand_dark, #41493d);
  gap: 16px;
  padding: 14px 0;
}

.condition__text {
  font: var(--font--body-l);
  color: var(--color--primary_dark, #292e27);
}

.condition {
  --animate-delay: .35s;
}

.condition:nth-of-type(2) {
  animation-delay: calc(var(--animate-delay) * 2);
}

.condition:nth-of-type(3) {
  animation-delay: calc(var(--animate-delay) * 3);
}

.condition:nth-of-type(4) {
  animation-delay: calc(var(--animate-delay) * 4);
}

.condition:nth-of-type(5) {
  animation-delay: calc(var(--animate-delay) * 5);
}

.condition:nth-of-type(6) {
  animation-delay: calc(var(--animate-delay) * 6);
}

.condition:nth-of-type(7) {
  animation-delay: calc(var(--animate-delay) * 7);
}

.certificates__title {
  color: var(--color--primary_dark, #292e27);
  margin-bottom: 48px;
}

@media (min-width: 1200px) {
  .certificates__title {
    margin-bottom: 56px;
  }
}

@media (min-width: 1440px) {
  .certificates__title {
    margin-bottom: 80px;
  }
}

.certificates__list {
  display: flex;
}

.certificate__link {
  height: 236px;
  border: 1px solid var(--color--brand_light, #cbd6c5);
  border-radius: var(--border-radius);
  transition: var(--transition);
  overflow: hidden;
}

@media (min-width: 480px) {
  .certificate__link {
    height: 296px;
  }
}

@media (min-width: 768px) {
  .certificate__link {
    height: 236px;
  }
}

@media (min-width: 1200px) {
  .certificate__link {
    height: 240px;
  }
}

@media (min-width: 1440px) {
  .certificate__link {
    height: 284px;
  }
}

.certificate__link:focus {
  outline: none;
}

.certificate__img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: auto;
  transition: var(--transition);
}

.test .certificate__img {
  max-height: 300px;
  width: auto;
}

.certificate__link:hover .certificate__img, .certificate__link:focus .certificate__img {
  scale: 1.05;
}

.certificates-swiper-pagination {
  margin-top: 20px;
  margin-bottom: 10px;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: var(--color--brand_light, #cbd6c5);
  opacity: 1;
  border-radius: 50px;
  transition: all .2s linear;
}

.swiper-pagination-bullet-active, .swiper-pagination-bullet:hover, .swiper-pagination-bullet:focus {
  background-color: var(--color--brand, #7f8f77);
  outline: none;
}

.swiper-pagination-bullet:active {
  background-color: var(--color--brand_dark, #41493d);
}

.swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
}

.swiper-button-prev, .swiper-button-next {
  width: 48px;
  height: 48px;
  padding: 8px;
}

@media (max-width: 1199px) {
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 0;
}

.sl-overlay {
  z-index: 8;
  background: #292e27fa;
  opacity: 1 !important;
}

.sl-wrapper {
  z-index: 9;
  width: 100%;
  height: 100%;
  touch-action: none;
  padding: 16px;
  top: 0;
  left: 0;
}

.sl-image {
  z-index: 3;
  touch-action: none;
}

.sl-image img {
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
  max-height: calc(100vh - 32px) !important;
}

@media (max-width: 767px) {
  .sl-image {
    max-height: calc(90vh - 32px) !important;
  }
}

.sl-close {
  z-index: 4;
  width: 48px;
  height: 48px;
  background-color: var(--color--brand_light, #cbd6c5);
  font-size: 0;
  top: 16px;
  right: 16px;
  -webkit-mask-image: url("icon-btn-cross.922b2b11.svg");
  mask-image: url("icon-btn-cross.922b2b11.svg");
}

.sl-close:hover {
  background-color: var(--color--brand, #7f8f77);
}

.sl-close:focus {
  outline: 4px solid var(--color--brand, #7f8f77);
  border-radius: 30px;
}

.sl-close:focus:not(:focus-visible) {
  background-color: var(--color--brand_dark, #41493d);
  outline: none;
}

.sl-close:active {
  background-color: var(--color--primary_dark, #292e27);
}

.sl-navigation {
  z-index: 6;
  width: calc(100vw - 32px);
  height: 0;
  display: flex !important;
}

.swiper-button-prev, .swiper-button-next {
  background-color: var(--color--brand_dark, #41493d);
}

.sl-prev, .sl-next {
  background-color: var(--color--brand_light, #cbd6c5);
  z-index: 6;
}

.swiper-button-prev, .swiper-button-next, .sl-prev, .sl-next {
  width: 48px;
  height: 48px;
  font-size: 0;
}

.swiper-button-prev:hover, .swiper-button-next:hover, .sl-prev:hover, .sl-next:hover {
  background-color: var(--color--brand, #7f8f77);
}

.swiper-button-prev:focus, .swiper-button-next:focus, .sl-prev:focus, .sl-next:focus {
  outline: 4px solid var(--color--brand, #7f8f77);
  border-radius: 30px;
}

.swiper-button-prev:focus:not(:focus-visible), .swiper-button-next:focus:not(:focus-visible), .sl-prev:focus:not(:focus-visible), .sl-next:focus:not(:focus-visible) {
  background-color: var(--color--brand_dark, #41493d);
  outline: none;
}

.swiper-button-prev:active, .swiper-button-next:active, .sl-prev:active, .sl-next:active {
  background-color: var(--color--primary_dark, #292e27);
}

.swiper-button-prev, .sl-prev {
  -webkit-mask-image: url("icon-btn-prev.bc20ab53.svg");
  mask-image: url("icon-btn-prev.bc20ab53.svg");
}

.swiper-button-next, .sl-next {
  -webkit-mask-image: url("icon-btn-next.b5e51133.svg");
  mask-image: url("icon-btn-next.b5e51133.svg");
}

.sl-counter {
  display: none !important;
}

/*# sourceMappingURL=index.74ce6633.css.map */
