.about__container {
  @extend %relative;
  @extend %flex-ac-dc;
  // gap: 32px;
}
.about__container::before {
  content: '';
  @extend %absolute;
  left: var(--container-padding);
  bottom: calc(var(--section-padding) * (-1));

  display: block;
  width: calc(100% - var(--container-padding) * 2);
  height: 1px;

  background-color: #CBD6C5;
}
.about__title {
  // margin-bottom: 56px;
  margin-bottom: 48px;
  
  color: var(--color--brand_dark, #41493D);
  
  // @include breakpoint-min($tablet) { margin-bottom: 64px;}
  // @include breakpoint-min($desktop) { margin-bottom: 80px;}
  @include breakpoint-min($desktop) { margin-bottom: 56px;}
  @include breakpoint-min($desktopL) { margin-bottom: 80px;}
}
.about__box-wrap {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  @include breakpoint-min($tablet) {
    grid-template-columns: 336px auto;
    grid-template-rows: auto 1fr;
    gap: 16px;
  }
  @include breakpoint-min($desktop) { 
    grid-template-columns : 344px auto; 
    gap: 24px;
    max-width: 896px;
  }
  @include breakpoint-min($desktopL) { 
    grid-template-columns : 408px auto; 
    max-width: 1056px;
  }
}
.about__box {
  &:nth-of-type(2) {
    @extend %flex-dc;
    gap: 8px;
  }

  @include breakpoint-max($tablet - 1) {
    grid-column: 1;
  }
  @include breakpoint-min($tablet) {
    &:nth-of-type(3) {
      grid-column: 1 / 3;
    }
  }
  @include breakpoint-min($desktop) {
    &:nth-of-type(1) {
      grid-row: 1 / 3;
    }
    &:nth-of-type(3) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
}

.about__img {
  flex-shrink: 0;
  width: 100%;
  height: auto;

  object-fit: cover;
  border-radius: var(--border-radius);

  @include breakpoint-min($mobile) {
    width: 448px;
    height: 672px;
  }
  @include breakpoint-min($tablet) {
    width: 336px;
    height: 504px;
  }
  @include breakpoint-min($desktop) {
    width: 344px;
    height: 515px;
  }
  @include breakpoint-min($desktopL) {
    width: 408px;
    height: 612px;
  }
}
.about__text {
  font: var(--font--body-l);
  font-variant-numeric: lining-nums proportional-nums;

  &:not(:last-of-type) { margin-bottom: 12px; }
}
.about__link {
  font: var(--font--link-r);
  color: var(--color--primary_dark, #292E27);

  border-radius: 26px;
}
.about__link:hover { color: var(--color--brand, #7F8F77); }
.about__link:focus { outline: 6px solid var(--color--brand_light, #CBD6C5); }
.about__link:focus:not(:focus-visible) { outline: none; color: var(--color--brand_dark, #41493D); }
.about__link:hover { color: var(--color--brand_dark, #41493D); }


// &:focus {
//   border: 6px solid var(--color--brand, #7F8F77);
// }
// &:focus:not(:focus-visible) {
//   border: 1px solid var(--color--brand_dark, #41493D);
// }
// &:active {
//   color: var(--color--primary_dark, #292E27);
//   border: 1px solid var(--color--primary_dark, #292E27);
//   background: var(--color--brand, #7F8F77);
// }

.about__link--education {
  display: inline-block;
  // width: 140px;
  height: 50px;
  padding: 12px 8px;

  border-radius: 26px;
}
.about__item {
  @extend %relative;
  display: flex;
  align-items: center;
  padding-left: 24px;

  font: var(--font--body-l);

  &::before {
    content: '';
    @extend %absolute;
    top: 10px;
    left: 10px;

    width: 4px;
    height: 4px;

    border-radius: 50%;
    background-color: var(--color--primary_dark, #292E27);
  }
}

// for animate
// .animate__animated.about__box-wrap {--animate-delay: 1s; }
// .animate__animated.about__box:nth-of-type(1) {--animate-delay: 0ms; }
.animate__animated.about__box:nth-of-type(2) {--animate-delay: 500ms; }
.animate__animated.about__box:nth-of-type(3) {--animate-delay: 1000ms; }
