.conditions {
  background-color: var(--color--brand_light, #CBD6C5);
}
.conditions__container {
  @extend %flex-ac-dc;
}
.conditions__title {
  // margin-bottom: 56px;
  margin-bottom: 48px;

  color: var(--color--primary_dark, #292E27);
  @extend %text-ac ;

  // @include breakpoint-min($tablet) { margin-bottom: 64px;}
  // @include breakpoint-min($desktop) { margin-bottom: 80px;}
  @include breakpoint-min($desktop) { margin-bottom: 56px;}
  @include breakpoint-min($desktopL) { margin-bottom: 80px;}
}
.conditions__list {
  @extend %flex-dc;
  gap: 4px;

  @include breakpoint-min($tablet) { max-width: 512px; }
  @include breakpoint-min($desktop) { max-width: 528px; }
  @include breakpoint-min($desktopL) { max-width: 624px; }
}

.condition {
  border-bottom: 1px solid var(--color--brand, #7F8F77);

  &:first-of-type { border-top: 1px solid var(--color--brand, #7F8F77);}
}
.condition__title {
  @extend %relative;
  @extend %flex-ac;
  justify-content: space-between;
  width: 100%;
  height: 72px;

  color: var(--color--primary_dark, #292E27);
  text-align: left;

  background-color: var(--color--brand_light, #CBD6C5);
  @extend %cursor;
}
.condition__btn {
  @extend %relative;
  @extend %flex-ac-jc;
  width: 48px;
  height: 48px;
  padding: 8px;

  background-color: transparent;
  --background-color: var(--color--brand_dark, #41493D);


  &.isActive {--background-color: var(--color--primary_dark, #292E27);}
  &:hover {--background-color: var(--color--brand, #7F8F77) }
  &:focus { outline: 4px solid var(--color--brand, #7F8F77); border-radius: 30px; }
  &:focus:not(:focus-visible) { outline: none; }
  &:active {--background-color: var(--color--primary_dark, #292E27) }
}
.condition__btn::before,
.condition__btn::after {
  content: '';

  display: block;
  width: 20px;
  height: 2px;

  border-radius: 12px;
  background-color: var(--background-color);
  transition: 200ms linear;
}
.condition__btn::before {
  @extend %absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  // transition: 200ms linear;
}
.condition__btn.isActive::before { width: 0;}

.condition__desc-box {
  display: grid;
  grid-template-rows: 1fr;

  transition: 200ms linear;

  &.isHidden {
    grid-template-rows: 0fr;
  }
}
.condition__desc-inner {
  overflow: hidden;
}
.condition__desc {
  @extend %flex-dc;
  gap: 16px;
  padding: 14px 0;

  border-top: 1px solid var(--color--brand_dark, #41493D);
}
.condition__text {
  font: var(--font--body-l);
  color: var(--color--primary_dark, #292E27);
}


// for animate
.condition {--animate-delay: 350ms; }
.condition:nth-of-type(2) {animation-delay: calc(var(--animate-delay)*2);}
.condition:nth-of-type(3) {animation-delay: calc(var(--animate-delay)*3);}
.condition:nth-of-type(4) {animation-delay: calc(var(--animate-delay)*4);}
.condition:nth-of-type(5) {animation-delay: calc(var(--animate-delay)*5);}
.condition:nth-of-type(6) {animation-delay: calc(var(--animate-delay)*6);}
.condition:nth-of-type(7) {animation-delay: calc(var(--animate-delay)*7);}