// common styles
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';
// components styles
@import '/src/components/btns/btns.scss';
@import '/src/components/header/header.scss';
@import '/src/components/footer/footer.scss';
@import '/src/components/lang/lang.scss';
@import '/src/components/mobile-menu/mobile-menu.scss';
@import '/src/common/scss/animation.scss';
// sections styles
@import '/src/components/section/hero/hero.scss';
@import '/src/components/section/about/about.scss';
@import '/src/components/section/problems/problems.scss';
@import '/src/components/section/conditions/conditions.scss';
@import '/src/components/section/certificates/certificates.scss';