footer {
  @extend %flex-ac-dc;
  gap: 40px;
  width: 100%;
  padding: 44px 0 24px;

  color: var(--color--primary_dark, #292E27);
  background-color: var(--color--brand_light, #CBD6C5);

  @include breakpoint-min($desktop) { padding: 80px 0 40px; }
  @include breakpoint-min($desktopL) { gap: 56px; }
}

.footer__top,
.footer__bottom {
  width: 100%;
}
.footer__container {
  @extend %flex-ac-dc;
  gap: 16px;

  @include breakpoint-min($desktop) { 
    flex-direction: row;
    justify-content: center;
    gap: 68px;
  }
  @include breakpoint-min($desktop) { 
    gap: 124px;
  }
}
.footer__box:first-of-type {
  max-width: 300px;
}

.footer__title {
  color: var(--color--brand_dark, #41493D);
  @extend %text-ac;

  @include breakpoint-min($desktop) {text-align: start;} 
}
.footer__subtitle {
  font: var(--font--body-l);
  color: var(--color--primary_dark, #292E27);
  @extend %text-ac;

  @include breakpoint-min($desktop) {text-align: start;} 
}
.contacts__list {
  @extend %flex-dc;
  gap: 4px;
}
.contact__link {
  @extend %flex-ac;
  gap: 6px;
  padding: 7px 8px;

  font: var(--font--body-r);
  font-variant-numeric: lining-nums proportional-nums;
  color: var(--color--primary_dark, #292E27);
  text-decoration: none;
  border-radius: 26px;
}
.contact__link:hover { color: var(--color--brand, #7F8F77); }
.contact__icon {
  flex: 0 0 24px;
}

.messengers__list {
  @extend %flex-ac-jc;
  gap: 14px;
}
.messenger {
  width: 64px;
}
.messenger__link {
  @extend %flex-ac-dc;
  gap: 4px;

  color: var(--color--primary_dark, #292E27);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
  text-decoration: none;

  border-radius: 10px;

  &:hover {
    color: var(--color--brand_dark, #41493D); 
  
    svg {fill: var(--color--brand, #7F8F77)}
  }
  &:focus {
    outline: 5px solid var(--color--brand, #7F8F77);
  
    svg {fill: var(--color--brand_dark, #41493D)}
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:active {
    svg {fill: var(--color--brand_dark, #41493D)}
  }
}

.messenger__icon {
  flex: 0 0 40px;
}

.copyright {
  font: var(--font--body-small-l);
  font-variant-numeric: lining-nums proportional-nums;
  color: var(--color--primary_dark, #292E27);
  @extend %text-ac;
}